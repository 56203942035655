.notes-container {
  margin-top: 24px;

  .notes-view {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    div[editext='view'] {
      background: #fff3cd;
      color: #856404;
      padding: 16px;
      border-radius: 6px;
      border: 1px solid #ffec18;
      white-space: pre-wrap;
      width: 100%;
      text-align: justify;
    }

    textarea {
      border-radius: 6px;
      height: 300px;
    }

    button {
      margin: 10px 0 0 10px;
    }
  }
}
