@import 'styles/colors';

.select {
  &-field {
    -webkit-appearance: none;
    -moz-appearance: none;
    font-size: 1rem;
    color: $spicy_pink;
    height: 3rem;
    cursor: pointer;
    z-index: 2;
    position: absolute;
    padding: 0;
    border: 0;
    border-radius: unset;
    margin: 0;
    top: 0;
    /* left: 20px; */
    right: 0;
    padding-left: 20px;
  }

  &-container {
    background: $cloud_burst_opacity;
    position: relative;
    border: none;
    border-radius: 4px;
    padding: 14px 18px;
    height: 48px;
  }

  &-button {
    background-color: $white;
    position: absolute;
    top: 0;
    right: 0;
    padding: 11px;
    width: 48px;
    text-align: center;
    border: 1px solid $porcelain;
    box-sizing: border-box;
    border-radius: 4px;
    cursor: pointer;

    &-arrow-down {
      display: inline-block;
      vertical-align: middle;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid $amaranth;
    }
  }
}

.step-container .step .current-step .form-control.select-field {
  background-color: transparent;
}

.step .xhr-select input {
  height: auto !important;
}

.district-id {
  font-family: monospace;
  color: rgb(7, 71, 166);
  background: rgb(222, 235, 255);
  display: inline-block;
  border-radius: 4px;
  vertical-align: middle;
  max-width: 98%;

  span {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%;
    padding: 2px 6px;
    font-size: 0.6rem;
  }
}
