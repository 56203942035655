.colour {
  width: 25px;
  height: 25px;
  display: inline-block;
  border-radius: 3px;
  margin: 5px 10px;
  cursor: pointer;
  position: relative;
}

.fa-check {
  position: absolute;
  padding-left: 6px;
  left: 0;
  top: 4px;
  color: #fff;
  display: none;
}

.colour.selected .fa-check {
  display: block;
}

.color-picker input {
  height: 32px !important;
}
