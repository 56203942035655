@media (max-width: 767px) {
  .sdp-data-container {
    padding: 0 0.625rem;
  }

  .sdp-courses-n-teachers-column {
    margin-top: 1.875rem;
  }
}
@media (min-width: 768px) and (max-width: 1189px) {
  .sdp-data-container {
    padding: 0 0.938rem;
  }
}
@media (min-width: 1190px) {
  .sdp-data-container {
    padding: 0;
  }
}
