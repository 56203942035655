@import 'styles/colors';

.courses-teachers-users-table {
  & .react-bootstrap-table {
    min-width: 600px;
  }

  background-color: $white;

  border: 0.063rem solid rgba(18, 38, 109, 0.1);
  border-radius: 0.25rem;
  padding: 1.563rem 0;

  .tab-content {
    padding: 0 1.563rem;
  }

  .nav-tabs {
    padding-left: 25px;
    height: 40px;

    .nav-item {
      color: $spicy_pink;
      font-size: 14px;
      line-height: 17px;
    }

    .active {
      color: $amaranth;
      background: $white;
    }
  }

  svg {
    display: inline;
  }
}

.calendar-build {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;

  .btn {
    margin-left: 10px;
  }

  .spinner-grow {
    width: 1rem;
    height: 1rem;
  }
}

#calendar-import-popover > .arrow::before {
  border-bottom-color: rgba(0, 0, 0, 0.1) !important;
}

#calendar-import-popover {
  .switch-field {
    padding: 0;
    margin-top: 10px;
    .switch-field-label b {
      font-weight: normal;
    }
  }
}

.blocked-builder {
  .tooltip-inner {
    padding: 15px 20px;
    text-align: left;
  }
}
