@import 'styles/colors';
.school-grid-container {
  padding: 2% 5%;

  .row {
    padding: 1.25rem 0;
    align-items: center;
  }
}

.top-data-management {
  padding-left: 0;
  padding-right: 0;
  border: none;
}

.schools-count {
  font-size: 2.3rem;
  font-weight: bold;
  color: $amaranth;
  display: block;
}

.table-schools-count {
  font-size: 2rem;
  color: #12266d;
  margin-right: 10px;
}

.ripple {
  margin-left: 5px;
  color: $amaranth;
  font-size: 1.2rem;
  cursor: pointer;
}

.schools-title {
  padding-left: 0;
}

.dropdown-toggle {
  color: #000;
  background-color: #fff;
  border-color: rgba(18, 38, 109, 0.1);
}

.dropdown-toggle:hover,
.dropdown-toggle.active {
  color: #fff;
  background-color: red;
  border-color: rgba(18, 38, 109, 0.1);
}

.dropdown-toggle::after {
  color: red;
  vertical-align: middle;
}

.dropdown-toggle:hover::after {
  color: #fff;
}

.controls-block {
  padding-right: 0;
  text-align: right;
}

.school-grid-container .advanced-table-head-row {
  padding: 0;
  margin-bottom: 3.563rem;
}

.school-grid-container .advanced-content-table-row {
  padding-top: 0;
}

.school-grid-container .advanced-table-head-title h1 {
  margin-bottom: 0;
}

.controls-block .search-box {
  width: 50%;
}

.create-button .fa-plus {
  position: relative;
  top: 0.188rem;
  margin-right: 0.313rem;
}
