.login-container {
  position: absolute;
  background: url('../../assets/images/bg-2x.png');
  background-size: cover;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  padding: 0% 0 15%;

  .login-container-header img {
    max-width: 100%;
  }

  .env-badge {
    margin-top: 20px;
    margin-right: 0;
  }

  &-header {
    height: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      height: 36px;
      display: block;
    }

    .slash {
      font-weight: 500;
      font-size: 36px;
      color: #ea3f58;
      line-height: 40px;
      display: block;
      margin: 0 5px;
    }

    .header-title {
      font-weight: 500;
      font-size: 32px;
      color: #ea3f58;
      line-height: 36px;
      display: block;
    }
  }

  .error-access {
    margin: 0 auto;
    color: red;
  }
}
