.react-bootstrap-table-editing-cell {
  .alert-danger {
    background: transparent;
    border: none;
    padding: 0;
    width: 100%;
    font-size: 0.7rem;
    margin: 0;
    strong {
      font-weight: normal;
      color: #dc3545;
    }
  }

  .form-group {
    margin: 0;
  }

  .hour-picker {
    display: block;
    max-width: 100px;
    height: 38px !important;
    width: auto;
    text-align: center;
  }
}

.wizard-form .react-bootstrap-table .invalid {
  background: #ffebeb;
}

.DayPickerInput {
  width: 100%;

  .DayPicker-Caption {
    display: table-caption;
    margin-bottom: 0.5em;
    padding: 0.4em 0.5em 0 3.5em;
    text-align: left;
  }
}

.DayPicker-wrapper {
  padding-bottom: 0;
}

.DayPickerInput-Overlay {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  box-shadow: none;
}

.DayPicker-Day--today {
  color: #ea3f58;
}

.DayPicker-Day {
  width: 40px;
}

/* Match Bootstrap Styles */
.DayPickerInput input {
  font-weight: 400;
  line-height: 1.5;
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  background: rgba(38, 45, 83, 0.05);
  border-radius: 4px;
  border: none;
  font-size: 16px;
  color: #786d6c;
  margin: 5px 0;
}

.DayPickerInput input:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
