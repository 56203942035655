.upload__image-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .preview {
    height: 50px;
    text-align: center;
    display: flex;
    align-items: center;

    img {
      padding: 0 10px 0 0;
    }
  }

  .drop-area {
    border: 2px dashed #dadada;
    border-radius: 6px;
    height: 48px;
    text-align: center;
    width: 100%;
    line-height: 48px;
    font-size: 0.9rem;
    background: #f9f9f9;
  }
}
