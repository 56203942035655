@import 'styles/colors';

.create-school {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(28, 27, 30, 0.7);
}

.full-screen-modal .modal-dialog {
  max-width: 448px;
  margin: 0 auto;
}

.full-screen-modal .modal-content {
  height: 100%;
  border: none;
  border-radius: 0;
  background: transparent;
  text-align: center;
}

.full-screen-modal .modal-footer {
  background: $white;
}

.full-screen-modal form {
  text-align: left;
  width: 100%;
  max-width: 540px;
  margin: 10px auto 40px;
}

.step {
  display: flex;
  flex-direction: column;
  background: $white;
  border-radius: 4px 4px 0 0;
}

.step h2 {
  font-size: 20px;
  font-weight: 600;
  line-height: 22px;
  text-align: center;
  color: $black;
  margin: 20px 0;
  padding-top: 10px;
}

.step label {
  font-size: 16px;
  line-height: 22px;
  color: $spicy_pink;
  margin: 5px 0 0 5px;
}

.step #react-select-2-input {
  height: auto !important;
}

.back-btn {
  margin-right: auto !important;
}

.schedules .modal-dialog,
.comparison .modal-dialog {
  max-width: 950px;

  .react-bootstrap-table {
    padding: 0 1.5rem;
  }

  & .table-bordered td {
    border-bottom: 1px solid #dee2e6;
  }

  & .table-condensed td {
    padding: 0.75rem;
    height: 65px;

    .text-editor {
      height: 40px;
      border: 1px solid #cccccc !important;
      outline: none !important;
      box-shadow: none;
      padding-left: 10px;
      background: #ffffff;
    }

    .switch-field {
      padding: 0 13px;
    }

    input {
      border: 1px solid #cccccc;
      height: auto;
      padding: 2px 5px;
      margin: 0 4px;
      color: black;
      outline: none;
    }
  }
}

.wizard-form {
  .multifield-row {
    display: flex;
    justify-content: space-between;

    .form-action {
      padding: 0 0 0 10px;
      margin-bottom: 1rem;
      display: flex;

      button {
        align-self: flex-end;
      }
    }

    .form-group {
      &:first-child {
        margin-right: 10px;
      }
    }
  }

  .form-group {
    flex-grow: 1;

    .form-label {
      margin: 0;
      font-weight: bold;
      font-size: 0.8rem;
      color: #474747;
    }

    .form-control {
      &:disabled {
        background: #f2f2f2;
      }
    }

    &.extra-margin {
      margin-left: 10px;
    }

    &.extra-padding {
      .switch-field .react-switch {
        margin-top: 10px;
      }
    }

    .react-datepicker-wrapper {
      display: block;
    }
  }

  .switch-field {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    &-label b {
      font-size: 0.8rem;
      color: #474747;
    }
  }

  .numeric-field {
    padding: 0;
    &-label {
      font-size: 0.8rem;
      color: #474747;
      font-weight: bold;
    }
  }

  .quantity {
    border: 0;
    &:focus {
      outline: none;
    }
  }

  .wizard-nav-buttons {
    display: flex;
    justify-content: space-between;

    .btn {
      font-weight: bold;
      margin-left: 5px;

      &-secondary {
        background: #9c9c9c;
        border: none;
      }
    }

    .cancel {
      justify-self: flex-start !important;
      margin: 0;
    }
  }

  .schedule-review {
    .text-muted {
      span {
        display: block;
        line-height: 1.5rem;
        font-size: 1rem;
      }

      .schedule-tag {
        display: inline;
        line-height: 0.5rem;
        font-size: 0.6rem;
      }
    }

    hr {
      margin-bottom: 0;
    }
  }

  .draft-mode {
    display: flex;
    justify-content: flex-end;
  }

  .waves-list,
  .period-list {
    & > h5 {
      font-size: 1rem;
      margin: 10px 30px 0 30px;
      padding-bottom: 10px;
      border-bottom: 1px solid #e5e5e5;
    }
  }

  .waves-list .empty-waves,
  .period-list .empty-waves {
    margin: 10px 40px;
  }

  .schedule-period:last-child {
    border-bottom: none;
  }
}

.submitting-step {
  padding: 40px;
  .submitting {
    color: #07c493 !important;
    display: block;
    width: 6rem;
    height: 6rem;
    margin: 40px auto;
  }

  .text-success {
    color: #07c493 !important;
  }
}

.submitted-step {
  padding: 40px;

  .result-icon {
    font-size: 6rem;
    margin: 40px auto;

    &.success {
      color: #07c493;
    }

    &.error {
      color: $amaranth;
    }
  }

  .btn {
    font-weight: bold;
  }

  .message {
    margin-bottom: 30px;

    &.success {
      color: #07c493;
    }

    &.error {
      color: $amaranth;
    }
  }
}

.toggle-wrapper {
  margin: 0.5em auto;
}

.toggle-wrapper label {
  padding: 0.3em;
  font-size: 17px;
  display: block;
}

.toggleContainer {
  width: 100%;
  position: relative;
  background: #ffffff;
  border: 1px solid #eeeeee;
  border-radius: 5px;
  overflow: hidden;
}

.toggleContainer .toggleOption {
  display: inline-block;
  position: relative;
  z-index: 1;
  text-align: center;
  height: 40px;
  line-height: 40px;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
  color: #333333;
  font-size: 1em;
  font-weight: 400;
}

.toggleContainer .toggleOption.selected {
  color: #ffffff;
  cursor: initial;
}

.toggleContainer .toggle {
  position: absolute;
  height: 100%;
  bottom: 0;
  left: 0;
  transition: all 0.4s ease-in-out;
  background: #df5d5d;
}

.optionDisabled {
  background: #e6e6e6;
  cursor: no-drop !important;
}
