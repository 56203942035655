.fixed-dates .modal-dialog {
  max-width: 800px;

  .modal-content {
    max-height: 90vh;
  }

  .wide-select {
    .schedule-name-picker {
      width: 200px;
    }
  }
}

.review .modal-dialog {
  max-width: 720px;
}

.rotation-dates-table {
  max-height: calc(60vh - 1rem - 40px);
  overflow-y: auto;
}
