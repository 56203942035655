@import 'styles/colors';

.saturn-calendar {
  border: 0.063rem solid $gulf_blue_opacity;
  margin-top: 10px;

  .DayPicker {
    display: block;

    &-Day--today .calendar-day-container {
      background-color: #1abc9c;
      color: white !important;

      .calendar-day-extra-content {
        color: $white;
      }

      &--disabled .calendar-day-date {
        color: white !important;
      }
    }

    &-Caption {
      border-bottom: 1px solid $deep_koamaru_opacity;
      padding: 10px 0 10px 80px;

      select {
        margin-top: 4px;
        padding: 8px;
        background: white;
        border-radius: 4px;
        margin-left: 5px;
        border: 1px solid $deep_koamaru_opacity;
      }

      .today-button {
        margin-left: 20px;
        margin-bottom: 4px;
      }
    }

    &-Months {
      width: 100%;
    }

    &-Month {
      margin: 0;
      width: 100%;
    }

    &-Day {
      width: 14%;
      max-width: 14%;
    }

    &-Weekday {
      color: $black;
    }

    &-Body {
      padding: 10px;
    }

    &-wrapper {
      outline: transparent;
    }
  }

  .calendar-day {
    &-container {
      position: relative;
      width: 100%;
      height: 72px;
      border: 1px solid $deep_koamaru_opacity;
      border-radius: 4px;
      max-width: 156px;

      &--disabled {
        background-color: $porcelain;
        border: none;
        border-radius: 4px;

        .calendar-day {
          &-date {
            color: $spicy_pink;
          }

          &-extra-content {
            color: $spicy_pink;
          }
        }
      }

      &--missing {
        background-color: #adadad;
        border: none;
        border-radius: 4px;

        .calendar-day {
          &-date,
          &-extra-content {
            color: #ffffff;
          }
        }
      }

      &--modified {
        background-color: #128fe7;
        border: none;
        border-radius: 4px;

        .calendar-day {
          &-date,
          &-extra-content {
            color: #ffffff;
          }
        }
      }

      &--special {
        background-color: #e83f57;
        border: none;
        border-radius: 4px;

        .calendar-day {
          &-date,
          &-extra-content {
            color: #ffffff;
          }
        }
      }

      &--editable {
        background-color: $amaranth_opacity;
        border: none;
        border-radius: 4px;
        font-weight: bold;

        .calendar-day {
          &-date {
            color: $amaranth;
          }

          &-extra-content {
            color: $amaranth;
          }
        }
      }

      &:active,
      &:focus {
        background-color: #1abc9c;
        border: none;
        border-radius: 4px;
        font-weight: bold;
        outline: none;

        .calendar-day {
          &-date {
            color: $white;
          }

          &-extra-content {
            color: $white;
          }
        }
      }
    }
  }
}

/* *********************List view********************/
.DayPicker-NavButton {
  position: absolute;
  right: auto;
  left: 1.5em;
  margin-top: 8px;
}

.DayPicker-NavButton--next {
  margin-left: 1.5em;
}

.list-view .DayPicker-Weekdays {
  display: none;
}

.list-view .DayPicker-Week {
  display: grid;
}

.list-view .DayPicker-Day {
  display: flex;
  width: 100%;
  margin: 0;
  padding: 1.25rem 1.875rem;
  border-bottom: 0.063rem solid rgba(4, 21, 81, 0.1);
}

.list-view .calendar-day-container {
  width: 100%;
}

.list-view .calendar-day-date,
.list-view .calendar-day-extra-content {
  width: 50%;
  display: inline-flex;
}

.list-view .calendar-day-extra-content {
  justify-content: left;
}

.list-view .DayPicker-Months {
  margin-top: -7.588rem;
}

.list-view .DayPicker-Caption {
  margin-bottom: 5.275rem;
}

.DayPicker-Day button {
  border: none;
  background-color: initial;
}

.DayPicker-Day {
  border-radius: initial;
}

.calendar-day-date {
  font-size: 0.875rem;
  line-height: 1.063rem;
}

.calendar-day-extra-content {
  display: block;
  justify-content: center;
  font-size: 0.875rem;
  line-height: 1.063rem;
  color: #ea3f58;
}

.schedule-preview {
  border: 1px solid rgba(0, 0, 0, 0.2) !important;
  //box-shadow: 0px 0px 32px rgba(0,0,0,.3) !important;
  min-width: 250px !important;
  max-width: 250px !important;
  left: 30px !important;
  padding-bottom: 0 !important;
  overflow-y: visible !important;

  & .arrow {
    display: block;
  }

  & .schedule-period {
    padding: 5px 15px;
    margin: 0;

    h5 {
      font-size: 1rem;
    }

    div {
      font-size: 1rem;
    }
  }
}

.popover {
  background-color: $white;
  color: $black;
  width: auto;
  min-width: 500px;
  overflow-y: auto;
  max-height: 100vh;
  border: 1px solid $athens_gray_light;
  box-shadow: 0px 12px 32px $black_opacity;
  border-radius: 4px;
  left: 60px;
  padding-bottom: 20px;
  font-family: 'TT Norms' !important;

  &-container {
    &-title {
      font-weight: bold;
      padding: 10px 0;
      border-bottom: 1px solid $athens_gray_light;
    }

    .multifield-row {
      display: flex;

      .form-group {
        margin-right: 10px;

        .form-label {
          font-weight: bold;
        }

        &.large {
          flex-shrink: 2 !important;
          min-width: 160px;
        }
      }

      .form-action {
        padding: 0 0 0 0px;
        margin-bottom: 1rem;
        display: flex;

        button {
          align-self: flex-end;
        }
      }
    }

    &-actions {
      color: $azure_radiance_variant;
      padding: 10px 0;
      cursor: pointer;

      &-item {
        display: flex;
        align-items: flex-start;
      }
    }
  }

  &-schedule-types {
    &-title {
      font-weight: bold;
      padding: 10px 0;
      border-bottom: 1px solid $athens_gray_light;
      margin-bottom: 10px;
    }

    &-content {
      max-height: 350px;
      overflow-y: auto;

      &-option {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 10px;
        cursor: pointer;
        border-radius: 4px;

        &:hover {
          background: $porcelain;
        }

        &-text {
          margin-left: 20px;
          color: $spicy_pink;

          &-selected {
            color: $black;
            margin-left: 20px;
          }
        }
      }
    }
  }
}

.day-name-tag {
  background: #e7e7e7;
  padding: 4px;
  border-radius: 4px;
  font-family: monospace;
  color: #e6395c;
  margin: 0 5px;
  border: 1px solid #bcbcbc;
}

.btn.btn-success {
  background: #07c493;
}

.btn.btn-warning {
  color: #ffffff;
}

.btn.btn-danger {
  background: #e6395c;
}
