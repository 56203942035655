@import 'styles/colors';

.numeric-field {
  display: flex;
  justify-content: space-between;
  // border-top: 1px solid #E7E9F0;
  // border-bottom: 1px solid #E7E9F0;
  align-items: center;
  padding: 12px 24px;

  &-label {
    font-size: 14px;
  }

  &-content {
    display: flex;
    background: rgba(38, 45, 83, 0.05);
    border-radius: 4px;
    height: 48px;

    &-button {
      height: 100%;
      background-color: $white;
      width: 2.6rem;
      height: 100%;
    }

    &-minus {
      margin-left: 2px;
      height: 44px;
      margin-top: 2px;
    }

    &-plus {
      margin-right: 2px;
      height: 44px;
      margin-top: 2px;
    }

    &-cross {
      margin-right: 2px;
      height: 44px;
      margin-top: 2px;
    }

    &-quantity {
      width: 130px;
    }
  }
}

.number-input input[type='number'] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}
.number-input input[type='number']::-webkit-inner-spin-button,
.number-input input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.number-input div {
  -webkit-appearance: none;
  border: none;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
}
.number-input div:before,
.number-input div:after {
  display: inline-block;
  position: absolute;
  content: '';
  height: 2px;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}
.number-input div.plus:after {
  transform: translate(-50%, -50%) rotate(90deg);
}
.number-input input[type='number'] {
  text-align: center;
}

.number-input div.cross {
  &:after {
    transform: translate(-50%, -50%) rotate(45deg);
  }

  &:before {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
}

.number-input.number-input div:before,
.number-input.number-input div:after {
  width: 0.7rem;
  background-color: $amaranth;
}

.number-input.number-input input[type='number'] {
  background: transparent;
  margin: 0;
  padding: 0;
}
