.moderation-grid-container {
  .sdp-container-tabs {
    padding-left: 8%;
  }

  .moderation-quick-actions {
    padding: 20px 10%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: absolute;
    right: 0;
  }

  .nav-tabs {
    border: none;

    .nav-link {
      height: 36px;
      padding: 0 20px;
      margin-left: 5px;
      border-radius: 50px;
      line-height: 36px;
      background: #95a5a6;
      text-transform: uppercase;
      font-size: 0.9rem;
      color: #ecf0f1;

      &.active {
        background: #2c3e50;
        color: #ecf0f1;
      }
    }
  }
}

@media (max-width: 1048px) {
  .moderation-grid-container {
    .moderation-quick-actions {
      position: relative;
    }
  }
}

.tab-count {
  background: #ea3f58;
  border-radius: 50px;
  height: 20px;
  min-width: 20px;
  line-height: 20px;
  padding: 0 4px;
  font-size: 0.8rem;
  font-weight: bold;
  text-align: center;
  position: absolute;
  margin-top: -8px;
}
