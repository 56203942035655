@import 'styles/colors';

.places-auto-complete-content {
  position: relative;

  .address {
    &-search-input {
      width: 100%;
      padding: 0.375rem 0.75rem;
    }

    &-suggestion {
      position: absolute;
      background: $white;
      top: 3.125rem;
      border: 0.0625rem solid $spicy_pink;
      width: 100%;
      padding: 20px;
      z-index: 10;

      &-item {
        font-size: 1rem;
        color: $spicy_pink;
        margin: 0.3125rem 0;
        padding: 0.3125rem;
        border-bottom: 1px solid $spicy_pink;

        &--active {
          background-color: $azure_radiance;
          color: $white;
          cursor: pointer;
          margin: 0.3125rem 0;
          padding: 0.3125rem;
        }
      }
    }
  }
}
