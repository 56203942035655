#report-popover {
  min-width: 448px !important;
  overflow: visible;
  border-radius: 12px;
  padding: 1px;

  .detail-row {
    .event-detail {
      &.card {
        padding: 0 !important;
      }

      border-radius: 12px;
      overflow: hidden;

      .cover-wrapper {
        min-height: 150px;
        max-height: 150px;
        background-size: cover;
      }

      .event-title {
        padding-top: 20px !important;
        border: solid rgba(0, 0, 0, 0.1);
        border-width: 0 1px;
        margin-bottom: 0;
        padding-bottom: 0.75rem !important;
      }

      .card-text {
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-top: none;
        border-radius: 0 0 12px 12px;
      }

      .event-field-icon {
        color: grey;
        margin-right: 10px;
      }
    }
  }

  &.wide {
    min-width: 800px !important;

    .detail-row {
      display: flex;
      flex-wrap: wrap;
      margin-left: -15px;
      margin-right: -15px;
    }

    .report-comments {
      width: 100%;
      flex-basis: 0;
      flex-grow: 1;
      min-width: 0;
      overflow-y: auto;
      max-height: 365px;
    }
  }

  .card {
    display: flex;
    flex-direction: column;
    border-radius: 12px;
    width: 100%;
    height: fit-content;
    padding: 1.8rem 1.25rem !important;
    border: none;
    align-items: center;

    .card-body {
      width: 100%;
      padding: 0;

      .card-title,
      .card-subtitle,
      .card-text,
      .post-img {
        padding: 0 1rem;
      }

      .bulletin-post-detail {
        display: flex;
        flex-direction: column;

        .post-img {
          width: 50%;
          border-radius: 10px !important;
          align-self: center;
          padding: 0;
        }

        .post-link {
          margin-top: 20px;
          display: flex;
          justify-content: flex-start;
          .card-link {
            background: #ececec;
            color: #333333;
            border-radius: 5px;
            padding: 10px 16px;
            font-size: 18px !important;
            font-weight: bold;
          }
        }
      }

      .card-subtitle {
        margin-bottom: 0 !important;
      }

      .header-divider {
        margin-bottom: 0;
        box-shadow: 0 2px 6px 1px #ececec;
      }

      .chat-messages {
        padding: 15px 10px;
        min-height: 350px;
        max-height: 350px;
        overflow-y: auto;

        .message-counter {
          text-align: center;
          background: #9442de;
          border-radius: 50px;
          color: #ffffff;
          font-weight: bold;
          font-size: 0.8rem;
          width: 80%;
          margin: 0 auto 10px auto;
        }
      }

      .profile-detail {
        display: flex;
        align-items: center;

        .profile-pic {
          min-width: 70px;
          min-height: 70px;
          max-width: 70px;
          max-height: 70px;
          border-radius: 50px;
          background: rgba(0, 0, 0, 0.05);
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            max-width: 100%;
          }
        }
      }
    }
  }

  .bubble {
    width: 90%;
    border-radius: 0 12px 12px 12px;
    background: #f2f2f2;
    color: #333;
    padding: 5px 10px;
    position: relative;
    margin: auto auto 10px;

    &:before {
      content: '';
      width: 0;
      height: 0;
      position: absolute;
      top: 0;
    }

    .detail-pills {
      padding-top: 10px;
      .detail-pill {
        color: white;
        font-weight: bold;
        font-size: 12px;
        display: inline-block;
        margin: 5px 5px 5px 0;
        span:first-child {
          background: #3590ff;
          padding: 2px 5px 2px 5px;
          border-radius: 20px 0 0 20px;
        }
        span:last-child {
          background: #2cc9ff;
          padding: 2px 5px 2px 5px;
          border-radius: 0 20px 20px 0;
        }
      }
    }

    .own {
      background: linear-gradient(270deg, #2cc9ff 0%, #3590ff 100%);
    }

    &-left:before {
      border-left: 6px solid transparent;
      border-right: 6px solid #f2f2f2;
      border-top: 6px solid #f2f2f2;
      border-bottom: 6px solid transparent;
      left: -11px;
    }

    &-right:before {
      border-left: 6px solid transparent;
      border-right: 6px solid #3590ff;
      border-top: 6px solid #3590ff;
      border-bottom: 6px solid transparent;
      left: -11px;
    }

    .user-name {
      font-weight: bold;
      display: block;
      font-size: 0.7rem;
      margin: 0 0 5px 5px;
    }

    .content-text {
      margin-left: 5px;
    }

    img {
      max-width: 100%;
      margin-bottom: 10px;
    }
  }

  .report-comments {
    max-height: 300px;
    width: 100%;
    overflow-y: auto;
  }
}

.report-actions {
  display: flex;
  align-items: center;
  justify-content: center;
  .action-icon {
    margin: 0 0 0 10px;
    cursor: pointer;

    &.solved {
      cursor: not-allowed;
    }
  }

  .locked {
    color: #c3c3c3;
    cursor: not-allowed;
  }
}

.text-divider {
  display: flex;
  justify-content: center;
  width: 100%;
  overflow: hidden;
  align-items: center;
  padding-top: 15px;

  hr {
    align-self: center;
    flex-grow: 1;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }

  span {
    font-size: 14px;
    font-weight: 300;
    color: rgb(140, 153, 166);
    text-align: center;
    padding-left: 24px;
    padding-right: 24px;
    -webkit-box-flex: 0;
    flex-grow: 0;
    max-width: 550px;
    overflow-wrap: break-word;
  }
}
