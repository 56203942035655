#suspension-popover {
  min-width: 250px !important;
  padding: 20px;
  overflow: visible;

  .suspension-options-select {
    margin-bottom: 10px;
  }

  button {
    width: 100%;
    margin-top: 10px;
  }
}

#calendar-import-popover {
  min-width: 400px !important;
  padding: 20px;
  overflow: visible;

  .suspension-options-select {
    margin-bottom: 10px;
  }

  button {
    width: 100%;
    margin-top: 10px;
  }
}

#suspension-popover .arrow {
  display: block;

  &::before {
    border-bottom: none;
  }
}
