@import 'styles/colors';

.step-container {
  .step {
    position: relative;

    &-close-btn {
      position: absolute;
      right: 1.875rem;
      top: 1.875rem;
      color: $black;
      border: none;
      background: initial;
    }

    .current-step {
      padding: 24px 0;

      .form-control {
        background-color: $cloud_burst_opacity;
        border: none;
        border-radius: 4px;
        font-size: 14px;
        padding: 14px 18px;
      }

      &-toggle {
        width: 100%;
        height: 48px;
        margin-bottom: 15px;
        padding: 0 24px;
      }

      .form-group {
        position: relative;
        padding: 0 24px;
      }
    }
  }

  .modal {
    &-header {
      padding: 24px;
      background-color: $white;

      .modal-title {
        padding: 0;
        margin: 0;
        font-size: 16px;
        color: $deep_koamaru;
      }

      .close {
        margin: 0;
        padding: 0;
      }
    }

    &-footer {
      position: relative;
      justify-content: space-between;
      background-color: $white;

      .next-btn {
        width: inherit;
      }

      .cancel-btn {
        background-color: $amaranth;
        border: 0.0625rem solid $athens_gray;
        box-sizing: border-box;
        border-radius: 0.25rem;
        color: $white;
        width: inherit;
      }

      .btn-success {
        background-color: $caribbean_green;
        font-weight: bold;
      }
    }
  }
}
