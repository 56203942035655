@import 'styles/colors';

.root {
  display: flex;
  align-items: center;
}

.title {
  color: #333333 !important;
  opacity: 1 !important;
}

.loading-ripple {
  margin-left: 5px;
  color: $amaranth;
  font-size: 0.1rem;
  cursor: pointer;
}
