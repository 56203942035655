@import 'styles/colors';

.create-school {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(28, 27, 30, 0.7);
}

.full-screen-modal .modal-dialog {
  max-width: 448px;
  margin: 0 auto;
}

.full-screen-modal .modal-content {
  height: 100%;
  border: none;
  border-radius: 0;
  background: transparent;
  text-align: center;
}

.full-screen-modal .modal-footer {
  background: $white;
}

.full-screen-modal form {
  text-align: left;
  width: 100%;
  max-width: 540px;
  margin: 10px auto 40px;
}

.step {
  display: flex;
  flex-direction: column;
  background: $white;
  border-radius: 4px 4px 0 0;
}

.step h2 {
  font-size: 20px;
  font-weight: 600;
  line-height: 22px;
  text-align: center;
  color: $black;
  margin: 20px 0;
  padding-top: 10px;
}

.step label {
  font-size: 16px;
  line-height: 22px;
  color: $spicy_pink;
  margin: 5px 0 0 5px;
}

.step input {
  border-radius: 4px;
  border: 0.0625rem solid $iron;
  font-size: 16px;
  color: $spicy_pink;
  margin: 5px 0;
  height: 3rem;
  box-shadow: none !important;
  outline: none;
}

.step #react-select-2-input {
  height: auto !important;
}

.step #react-select-2-input {
  height: auto !important;
}

.back-btn {
  margin-right: auto !important;
}
