@import 'styles/colors';

.search-box {
  display: inline-flex;
  margin: 0 15px;
  margin-left: 0;
  width: 420px;
  height: 48px;

  &.disabled {
    opacity: 0.6;
    cursor: not-allowed;

    .input-group-prepend svg {
      color: grey;
    }

    .form-control {
      cursor: not-allowed;
    }
  }

  .result-counter {
    width: auto !important;
    padding: 0 10px !important;

    span {
      color: #ea3f58;
    }
  }

  .form-control {
    background-color: $white;
    border: 1px solid $iron;
    box-sizing: border-box;
    border-radius: 4px;
    height: 48px;
    padding-left: 20px;
    border-right: none;

    &:focus {
      box-shadow: none;
    }
  }

  .search-icon {
    color: #ea3f58;
  }

  .input-group {
    &-prepend {
      margin-right: 0;
      width: 48px;
    }

    &-text {
      background-color: $white;
      border: 1px solid $iron;
      box-sizing: border-box;
      border-radius: 4px;
      width: 48px;
      padding: 0;
      justify-content: center;
    }
  }

  .clear-button {
    background: white;
    border-left: none;

    svg {
      color: #aaadaf;
    }
  }
}
