.modal-result-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.modal-result-container h1 {
  font-size: 48px;
  line-height: 57px;
  text-align: center;
  color: #ffffff;
  margin-bottom: 50px;
}

.modal-result-container h1 svg {
  margin-right: 1.875rem;
  position: relative;
  top: 2px;
}

.modal-result-container .btn-success {
  background: #07c493;
  border-radius: 4px;
  width: 120px;
}

.modal-result-container .btn-error {
  background: #ea3f58;
  border-radius: 4px;
  width: 120px;
  color: white;
}

.success-icon {
  color: #07c493;
}

.error-icon {
  color: #ea3f58;
}
