.MuiChip-clickable.single-domain-chip {
  background-color: #eceef1;
  color: #786d6c;
  margin-right: 5px;
  margin-top: 5px;
}

.MuiChip-clickable.small,
.MuiChip-clickable.plus {
  background-color: #eceef1;
  color: #786d6c;
  margin-right: 5px;
  margin-top: 5px;
  font-size: 0.7rem;
  height: 20px;

  .MuiChip-label {
    padding: 0 8px;
  }
}

.MuiChip-clickable.plus {
  height: 20px;
  width: 20px;
}

.MuiChip-clickable.single-domain-chip:hover,
.MuiChip-clickable.single-domain-chip:focus {
  background-color: #eceef1;
  color: #786d6c;
}
