.network-status-modal {
  .advanced-table-head {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    .controls-row {
      width: auto;
      .search-box {
        width: auto;
        margin: 0;
      }
    }
    &-title {
      display: flex;
      align-items: flex-end;
      h1 {
        font-size: 1.2rem;
        line-height: 1.2rem;
        span {
          font-size: 1.5rem;
          margin-right: 2px;
        }
      }
    }
  }
  .schedule-name {
    display: flex;
    align-items: center;
    text-transform: capitalize;
  }
}
