@import 'styles/colors';

$stats-background: rgba(255, 255, 255, 0.05);
$stats-height: 9rem;
$transition-function: ease-in-out;
$transition-duration: 0.5s;

.map-page {
  height: 100%;
  width: 100%;
}

.home-map-row.full-size {
  padding: 0;
  height: 100% !important;
}

.loading-screen {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(13, 24, 63, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 2;

  & .map-ripple {
    color: $amaranth;
    width: 5rem;
    height: 5rem;
  }
}

.full-size .map-school-marker {
  width: 10px;
  height: 10px;
  transform: translate(-50%);

  &-live path {
    fill: #07c493 !important;
  }

  &-waitlist path {
    fill: #ffba3b !important;
  }

  &-suspended path {
    fill: #ea3f58 !important;
  }
}

.map-search-bar {
  position: absolute;
  left: 10px;
  padding: 5px 0;
  top: -15px;
}

.filter-controls {
  display: flex;
  align-items: center;

  .form-group {
    width: 170px;
    margin: 0 10px;
    border-radius: 50px;
    overflow: hidden;
  }
}

.mapboxgl-ctrl-group > button {
  width: 35px;
  height: 35px;
}

.filter-button {
  height: 40px;
  padding: 0 20px;
  margin-left: 5px;
  border: none;
  border-radius: 50px;
  color: #ffffff;
  font-weight: bold;
  outline: none !important;

  &.toggle-off {
    background: rgba(255, 255, 255, 0.2) !important;
    backdrop-filter: blur(2px);
  }

  &.all {
    background: #1271e9;
    border: none;
  }

  &.unlaunched.toggle-on {
    background: #ff9031 !important;
  }
}

.mapboxgl-popup-content {
  padding: 20px;
}

.dash-stats {
  background-color: $stats-background;
  background-size: 100% auto;
  cursor: default;
  height: $stats-height;
  padding: 0;
  color: white;
  display: flex;
  align-self: flex-end;
  align-items: center;
  justify-content: space-evenly;
  flex: 7;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  transition: bottom $transition-duration $transition-function;

  &.dash-stats-hidden {
    bottom: -$stats-height;
  }

  .stat-counter {
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(2px);
    border-radius: 8px;
    padding: 0.5rem 3rem;
    text-align: center;
    font-size: 1.4rem;
    flex: 16% 0;
  }

  .dash-stats-toggle {
    background-color: $stats-background;
    border-radius: 0.25rem 0.25rem 0 0;
    bottom: 100%;
    cursor: pointer;
    padding: 5px 5px 0 5px;
    position: absolute;
    right: 0;
  }
}

.map-filter-controls {
  position: absolute;
  width: 340px;
  top: 15%;
  left: 10px;
  transition: left $transition-duration $transition-function;
  display: flex;
  flex-direction: row;

  &.hide {
    left: -301px;
    transition: left $transition-duration $transition-function;
  }

  .row {
    padding-bottom: 0;
    padding-top: 0.7rem;
  }

  .controls {
    background: rgba(255, 255, 255, 0.4);
    backdrop-filter: blur(2px);
    border-radius: 8px;
    padding: 0 20px 20px;

    .form-group,
    .number-filter {
      width: 100%;
      margin-bottom: 0;

      label {
        font-weight: bold;
        display: block;
      }

      select {
        border-radius: 50px;
        border: none;
      }
    }

    .form-group input {
      border-radius: 50px;
      border: 0;
    }

    .number-filter {
      select {
        width: 30%;
        margin: 0;
        border-radius: 50px 0 0 50px;
        display: inline;
        border: 0;
        border-right: 1px solid #b8b8b8;
      }

      input {
        width: 70%;
        margin: 0;
        border-radius: 0 50px 50px 0;
        display: inline;
        border: 0;
      }
    }
  }

  .btn-toggle-filters {
    margin-top: 15px;
    //background: rgba(255, 255, 255, 0.4);
    //backdrop-filter: blur(2px);
    border: none;
    margin-left: -1px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    width: 40px;
    height: 40px;
  }
}

.mapboxgl-ctrl-bottom-right {
  display: none !important;
}
