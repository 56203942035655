$amaranth: #ea3f58;
$amaranth_strong: #e6395c;
$amaranth_opacity: rgba(234, 63, 88, 0.1);
$athens_gray: #eceef1;
$athens_gray_light: #e7e9f0;
$azure_radiance: #007bff;
$azure_radiance_opacity: rgba(36, 130, 233, 0.97);
$azure_radiance_variant: #0076ff;
$black: #000000;
$black_opacity: rgba(0, 0, 0, 0.1);
$caribbean_green: #07c493;
$cloud_burst_opacity: rgba(38, 45, 83, 0.05);
$deep_koamaru: #1337a3;
$deep_koamaru_opacity: rgba(18, 38, 109, 0.1);
$dusty_gray: #979797;
$gulf_blue_opacity: rgba(4, 21, 81, 0.1);
$iron: #dfe2e4;
$porcelain: #f4f5f6;
$spicy_pink: #786d6c;
$whisper: #f3f3f8;
$white: #ffffff;
