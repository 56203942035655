@import 'styles/colors';

.calendar-nav-bar {
  border-bottom: 0.063rem solid $athens_gray_light;
  height: 55px;

  .month-nav {
    color: #12266d;
    opacity: 0.35;
    width: 8.75rem;
    font-size: 1.125rem;
    line-height: 1.313rem;
    cursor: pointer;

    &-right {
      text-align: right;
    }
  }

  .arrows-nav-box {
    width: 210px;
    height: 40px;
    display: flex;
    justify-content: space-between;
    color: $amaranth;
    background: rgba(38, 45, 83, 0.05);
    border-radius: 4px;
    margin-left: 22px;
    margin-top: 15px;

    &-container {
      background: #ffffff;
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      border: 2px solid $porcelain;
      box-sizing: border-box;
      border-radius: 4px;
      cursor: pointer;
    }

    .calendar-nav-icon {
      margin: auto 0;
      z-index: 10;
      cursor: pointer;
    }
  }
}

.calendar-list-headers-row {
  display: flex;
  width: 100%;
  padding: 0.938rem 1.875rem;
  background-color: rgba(234, 237, 243, 0.25);
  border-bottom: 0.063rem solid rgba(4, 21, 81, 0.1);
  margin-bottom: 0.938rem;
}

.calendar-list-header {
  width: 50%;
  text-align: left;
}

.day-labels {
  display: inline-flex;
  justify-content: space-between;
  width: 500px;
  font-size: 1rem !important;
  font-weight: normal !important;
  margin-left: 20px;
}
