.network-grid-table-container {
  padding: 0 5%;

  .controls-row {
    max-height: 48px;
  }
}

.network-grid-container {
  .dash-stats-top {
    position: relative;
    background: #12266d;
    background: url('../../assets/images/bg-2x.png');
    background-size: 100% auto;
    background-position: center;
    padding: 10px 0 !important;
    margin-bottom: 0 !important;
    height: 8rem;

    .stat-counter {
      cursor: pointer;
      background: rgba(255, 255, 255, 0.13);
      backdrop-filter: blur(1px);
      font-size: 1rem;
      flex: 13.5% 0;
      padding: 0.3rem 0.3rem;
    }
  }

  .sdp-container-tabs {
    padding-left: 8%;
  }

  .nav-tabs {
    border: none;

    .nav-link {
      height: 36px;
      padding: 0 20px;
      margin-left: 5px;
      border-radius: 50px;
      line-height: 36px;
      background: #95a5a6;
      text-transform: uppercase;
      font-size: 0.9rem;
      color: #ecf0f1;

      &.active {
        background: #2c3e50;
        color: #ecf0f1;
      }
    }
  }
}

.tab-count {
  background: #ea3f58;
  border-radius: 50px;
  height: 20px;
  min-width: 20px;
  line-height: 20px;
  padding: 0 4px;
  font-size: 0.8rem;
  font-weight: bold;
  text-align: center;
  position: absolute;
  margin-top: -8px;
}

#schedule-review-popover {
  min-width: 350px !important;
  overflow: visible;
  border-radius: 6px;
  padding: 10px 0 20px 0;

  .wizard-form .schedule-period h5 {
    flex: 0;
  }
}

.school-filter {
  display: flex;

  .school-id {
    max-width: 90%;
    cursor: pointer;
  }

  a {
    margin: 0 10px;
  }
}
