.suggested-badge {
  font-size: 12px;
  font-weight: bold;
  padding: 2px 5px;
  margin-left: 5px;
  color: white;
  border-radius: 50px;

  &.missing {
    background: #ea3f58;
  }

  &.existing {
    background: #218838;
  }
}

.confirmation-dialog,
.confirmation-dialog-backdrop {
  z-index: 2000;
}
