div.ReactTags__tags {
  position: relative;
}

/* Styles for the input */
div.ReactTags__tagInput {
  border-radius: 2px;
  display: block;
}

div.ReactTags__tagInput input.ReactTags__tagInputField,
div.ReactTags__tagInput input.ReactTags__tagInputField:focus {
  background-color: rgba(38, 45, 83, 0.05);
  border: none;
  border-radius: 4px;
  font-size: 14px;
  padding: 14px 18px;
  width: 100%;
}

/* Styles for selected tags */
div.ReactTags__selected span.ReactTags__tag {
  background: #007bff;
  color: white;
  display: inline-block;
  margin: 0 5px 5px 0;
  cursor: move;
  border-radius: 4px;
  font-size: 14px;
  padding: 8px 18px;
}
div.ReactTags__selected a.ReactTags__remove {
  color: white;
  padding: 10px 0 10px 10px;
  cursor: pointer;
  font-size: 16px;
}

.ReactTags__remove {
  background: none;
  color: white;
  width: 20px;
  height: 20px;
  line-height: 0;
  font-size: 1.2rem;
  padding: 0;
  border: none;
  margin-right: -10px;
  margin-left: 10px;

  &:focus,
  &:focus-visible,
  &:focus-within {
    border: none;
    outline: none;
  }
}

.ReactTags__suggestions {
  ul {
    list-style: none;
    padding: 0;

    li {
      background: #dddddd;
      padding: 5px 15px;
      margin: 5px;
      border-radius: 50px;
      display: inline-block;
      font-size: 1rem;
      cursor: pointer;

      span {
        color: #333333;
      }
    }
  }
}
