@import 'styles/colors';

html,
body,
#root {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'TT Norms';
  background-color: $porcelain;
}

@font-face {
  font-family: 'TT Norms';
  font-style: normal;
  font-weight: 400;
  src: url('assets/fonts/TT-Norms-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'TT Norms';
  font-style: normal;
  font-weight: 500;
  src: url('assets/fonts/TT-Norms-Medium.woff2') format('woff2');
}

@font-face {
  font-family: 'TT Norms';
  font-style: normal;
  font-weight: 600;
  src: url('assets/fonts/TT-Norms-Bold.woff2') format('woff2');
}

.btn.disabled {
  cursor: not-allowed;
}

.btn-primary {
  font-weight: bold;
}

/* Modals */
.modal-content .btn-cancel {
  font-weight: 400;
  border: none;
  position: fixed;
  right: 1.875rem;
  top: 1.875rem;
  font-size: 14px;
  line-height: 16px;
  color: rgba(255, 255, 255, 0.5);
  background: none;
}

.btn-cancel::before {
  content: 'Cancel (esc)';
}

.btn-cancel .fa-times {
  color: #fff;
  margin-left: 10px;
}

@media (max-width: 960px) {
  .modal-content .btn-cancel {
    background-color: #fff;
    color: #333;
    position: absolute;
    right: 20px;
    padding: 10px;
    top: 20px;
  }

  .btn-cancel::before {
    content: '';
  }

  .btn-cancel .fa-times {
    color: #333;
    margin-left: 0;
  }
}

.row {
  margin: 0;
}

.modal-content {
  border: none;
}

#root > div {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.main-container,
.container-fluid {
  flex: 1;
}

.container-fluid {
  height: 100%;
}

.row {
  padding: 1.25rem 0;
}

.school-grid-container {
  padding: 2rem;
}
