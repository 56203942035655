@import 'styles/colors';

.schedules-ripple {
  color: $amaranth;
  display: flex;
  margin: 5rem auto;
  width: 50px;
  height: 50px;
}

.school-schedule {
  display: flex;
  margin-top: 25px;
  margin-left: 17%;
  margin-right: 17%;
  background-color: $white;
  padding: 30px 48px;
  justify-content: space-between;

  &-info {
    &-title {
      font-size: 14px;
      line-height: 22px;
      color: $amaranth;
    }

    &-sub-title {
      margin-top: 10px;
      font-size: 16px;
      line-height: 22px;
      color: $black;
      font-weight: bold;
    }

    &-disclaimer {
      margin-top: 10px;
      font-size: 14px;
      line-height: 20px;
      color: rgba(0, 0, 0, 0.5);
    }
  }

  &-actions {
    &-create {
      background-color: #07c493;
      border-radius: 4px;
      height: 40px;
      color: $white;
      box-shadow: none;
      border: none;
    }
  }
}

.schedule-actions {
  .btn .spinner-grow {
    width: 1rem;
    height: 1rem;
  }

  .switch-field-content {
    justify-content: center;
  }

  .btn-primary {
    font-weight: normal;
  }
}

.sdp-schedules {
  margin: auto;

  .schedules-view {
    padding-top: 20px;
    .schedule-actions {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      div {
        margin: 0 5px 0 0;
      }

      .btn {
        .svg-inline--fa {
          margin-right: 10px;
        }
      }
    }
  }
}

.editable-item {
  background: red;
  padding: 20px;
  height: 80px;
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  margin: 0 5px;

  .editable-area {
    width: 60px;
    text-align: center;
  }
}

.settings-panel {
  align-items: flex-start !important;
  padding-top: 40px !important;

  .quantity {
    border: 0;
    &:focus {
      outline: none;
    }
  }

  .lunch-class-creation-action {
    margin-top: 30px;
    display: flex;
    align-items: center;
  }

  .slot-picker {
    display: flex;
    align-items: baseline;
  }

  .types-wizard {
    background: #f4f5f6;
    padding: 15px;
    border-radius: 6px;
    margin-bottom: 20px;

    .types-select {
      margin-bottom: 10px;
    }

    .spinner-grow {
      margin: 0 auto;
      display: block;
    }

    .btn-large {
      width: 100%;
      margin-top: 15px;
    }
  }
}
