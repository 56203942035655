.table-container {
  border: 1px solid rgba(18, 38, 109, 0.1);
  margin-bottom: 25px;
}

.status-cell {
  color: white !important;
  font-weight: bold;
  background: #616161 !important;
}

.waitlist {
  background-color: #ffba3b !important;
}

.live {
  background-color: #07c493 !important;
}

.suspended {
  background-color: #ea3f58 !important;
}

.unlaunched {
  background-color: #ff9031 !important;
}

thead {
  background: rgba(234, 237, 243, 0.25);
  border-radius: 4px;
  text-transform: capitalize;
}

.empty-row-data {
  text-align: center;
}

.table td {
  vertical-align: middle;
}

.table td:first-child,
.table thead th:first-child {
  text-align: justify;
}

.table-bordered td,
.table-bordered th {
  border: none;
}

.status-cell {
  text-transform: capitalize;
}

.status-cell.red-status {
  color: #ea3f58;
}

.school-grid-name {
  color: #515151;
}

.actions-column {
  display: flex;
  justify-content: center;

  svg {
    margin: 0 5px;
    cursor: pointer;
  }
}
