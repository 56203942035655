@import 'styles/colors';

.react-bootstrap-table-pagination {
  display: flex;
  margin: 0;
  width: 50%;
}

.paginator-list-box {
  display: flex;
  border: 1px solid rgba(18, 38, 109, 0.1);
  border-radius: 4px;
  margin: auto;
}

.paginator-list-button {
  background-color: #fff;
  border: 1px solid;
  border-color: transparent rgba(18, 38, 109, 0.1) transparent transparent;
  font-size: 14px;
  line-height: 22px;
  align-items: center;
  text-align: center;
  color: #3f8eea;
}

.paginator-list-button:last-child {
  border-right: none;
}

.paginator-buttons-row {
  justify-content: space-between;
}

.react-bs-table-sizePerPage-dropdown.dropdown {
  z-index: 10;
}

.react-bootstrap-table-pagination div:first-child {
  margin-left: -0.938rem;
  margin-right: 0.938rem;
}

.advanced-table {
  &-dropdown {
    display: inline-flex;
    margin-right: 0.938rem;

    &-menu {
      padding: 0;
    }

    .dropdown-toggle {
      background-color: $white;
      border: 1px solid #dfe2e4;
      box-sizing: border-box;
      border-radius: 4px;
      color: $spicy_pink;
      font-weight: 400;
      position: relative;

      &:after {
        color: #ea3f58;
        position: absolute;
        right: 0.75rem;
        transform: translateY(-50%);
        top: 50%;
      }
    }

    &-item {
      text-transform: capitalize;
      color: #786d6c;
      font-size: 0.875rem;
      line-height: 1.5rem;
      padding: 0.938rem;
      width: 14.375rem;

      &:focus,
      &:hover {
        background-color: #3f8eea;
        color: #fff;
      }
    }
  }

  &-suggestions {
    width: 350px;
  }

  &-head {
    justify-content: space-between;

    &-title {
      h1 {
        color: #786d6c;
        font-size: 1.8rem;
        line-height: 2.625rem;
      }
    }

    .controls-row {
      align-self: flex-end;
      display: flex;
      justify-content: space-between;

      .switch-field {
        align-self: center;
        padding: 0 24px;
        &-label {
          margin-right: 10px;
        }
      }
    }
  }

  &-filter {
    width: 200px;
    margin-right: 10px;

    &__control {
      height: 100%;
    }
  }

  &-content {
    .order-4 {
      font-weight: bold;

      &:focus {
        color: $amaranth;
      }

      &:after,
      &:before {
        color: $dusty_gray;
      }
    }
    .table-bordered {
      thead {
        background-color: $whisper;

        th {
          vertical-align: top;
          &.sortable {
            outline: none;
          }

          .filter-label {
            margin-bottom: 0;
            display: block;
          }

          .form-control:focus {
            border-color: #ced4da;
            box-shadow: none;
          }

          select {
            padding: 3px;
          }

          .date-filter label {
            max-width: 85%;
          }

          .number-filter,
          .date-filter {
            display: flex;

            label {
              margin: 0;
            }

            select {
              width: 40px;
              border-right: none;
              border-top-right-radius: 0;
              border-bottom-right-radius: 0;
            }

            input {
              padding: 3px;
              border-top-left-radius: 0;
              border-bottom-left-radius: 0;
            }
          }
        }
      }

      tbody {
        background-color: $white;
      }
    }
  }
}

.advanced-table-head.row {
  padding-bottom: 0;
}

.advanced-table-dropdown,
.dropdown-menu.show {
  width: 140px;
  .advanced-table-dropdown-item {
    width: 100% !important;
  }
}

.school-id {
  cursor: pointer;
  font-family: monospace;
  color: #515151;
  background: #dadada;
  display: inline-block;
  border-radius: 4px;
  vertical-align: middle;
  max-width: 98%;

  span,
  a {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%;
    padding: 2px 6px;
  }

  a {
    color: #515151;
  }
}

.trim-line {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.table-actions {
  display: flex;

  .create-button,
  .upload-button {
    background-color: $amaranth;
    display: flex;
    align-items: center;
  }

  .upload-button {
    color: white;
    background-color: #636363;
    border-color: #636363;
    margin-left: 0.938rem;
  }
}

.row {
  align-items: inherit;
}

.caret-4-asc,
.caret-4-desc,
.order-4 {
  color: #ea3f58;
}

.advanced-table-dropdown .btn-primary {
  width: 14.375rem;
  text-align: left;
  font-weight: 400;
  display: inline-block;
}

.advanced-table-dropdown .btn-primary:not(:disabled):hover,
.show > .btn-primary.dropdown-toggle:hover {
  background-color: rgba(38, 45, 83, 0.1);
  color: #786d6c;
}

.advanced-table-dropdown .btn-primary:not(:disabled):hover::after,
.show > .btn-primary.dropdown-toggle:hover::after {
  color: #ea3f58;
}

.advanced-table-dropdown .btn-primary:not(:disabled):not(.disabled).active,
.advanced-table-dropdown .btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  background-color: rgba(38, 45, 83, 0.1);
  color: #786d6c;
}

.advanced-table-dropdown
  .btn-primary:not(:disabled):not(.disabled).active:hover,
.advanced-table-dropdown
  .btn-primary:not(:disabled):not(.disabled):active:hover,
.show > .btn-primary.dropdown-toggle {
  background-color: rgba(38, 45, 83, 0.1);
  color: #786d6c;
}

.advanced-table-dropdown
  .btn-primary:not(:disabled):not(.disabled).active:after,
.advanced-table-dropdown
  .btn-primary:not(:disabled):not(.disabled):active:after,
.show > .btn-primary.dropdown-toggle:after {
  color: #ea3f58;
}

.row.advanced-content-table-row {
  padding-bottom: 0;
}

.row.react-bootstrap-table-pagination {
  position: relative;
  width: 100%;
  padding-bottom: 0;
}

.react-bootstrap-table-pagination-list {
  margin: auto;
  position: absolute;
  width: 100%;
  max-width: 100%;
  padding: 0;
}

.pagination {
  justify-content: center;
  margin-bottom: 0;
  padding-bottom: 0;
}

.table {
  margin-bottom: 0;
}

.inner-table-row {
  border-bottom: 1px solid rgba(4, 21, 81, 0.1);
  color: #786d6c;
}

.advanced-table-suggestions__control {
  height: 100%;
  margin-right: 10px;
}

.advanced-table-content {
  overflow-x: scroll;

  & .react-bootstrap-table {
    min-width: 1200px;
  }
}
