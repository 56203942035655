@import 'styles/colors';

.modal-title {
  display: flex;
  align-items: flex-end;
  font-size: 1.3rem;

  .schedule-tag {
    font-size: 0.8rem;
    padding: 3px 10px;
    margin-left: 10px;
    border-radius: 12px;
    color: white;
    font-weight: 600;
    height: auto;
  }
}

.schedule-author {
  padding-top: 1rem;
  background: #ededed;
  p {
    padding: 0 0 0 1rem;
    margin: 0;
    font-size: 0.8rem;
    color: #505050;
  }
}

.alert-info {
  padding: 10px;
}

.schedule-detail {
  padding-bottom: 40px;
}

.schedule-period {
  border-bottom: 1px solid $deep_koamaru_opacity;
  margin: 0 30px;
  padding: 10px 20px;
  display: flex;
  flex-direction: row;
  flex: 3;
  align-items: center;

  h5 {
    color: $amaranth;
    flex: 1;
    margin: 0;
  }

  div {
    flex: 1;
    text-align: right;
    font-size: 1rem;
  }
}

.schedule-info {
  padding: 20px 30px;

  p {
    margin-bottom: 5px;

    b {
      font-weight: 500;
    }
  }

  .emoji-image {
    width: 32px;
    height: 32px;
  }
}
