.emoji-picker-popover {
  background: transparent;
  border: none;
  width: auto;
  overflow-y: visible !important;
  min-width: auto;
  padding-bottom: 0 !important;

  .emoji-mart {
    max-width: 100% !important;
    border: none;

    .emoji-mart-search {
      margin-bottom: 10px;

      .emoji-mart-search-icon {
        top: 4px;
      }
    }

    .emoji-mart-anchor-bar {
      display: none;
    }

    .emoji-mart-anchors img {
      height: 32px;
      width: auto;
    }
  }
}
