.switch-field {
  padding: 18px 24px;

  &-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &-label {
    font-size: 14px;
    text-transform: capitalize;
  }

  &-disclaimer {
    font-family: SF Pro Display;
    font-size: 12px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: rgba(120, 109, 108, 0.6);
    width: 58%;
  }

  .react-switch-bg {
    background: red;
  }
}
