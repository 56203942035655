.map-search-bar {
  .react-autosuggest__container {
    position: relative;
  }

  .react-autosuggest__input {
    width: 400px;
    height: 40px;
    padding: 10px 15px;
    border: none;
    border-radius: 50px;
    background: rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(2px);
    color: white;

    &::placeholder {
      color: #ffffff;
    }
  }

  .react-autosuggest__input--focused {
    outline: none;
  }

  .react-autosuggest__suggestions-container {
    display: none;
  }

  .react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    top: 51px;
    width: 400px;
    max-height: 350px;
    overflow-y: auto;
    border: none;
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: 8px;
    z-index: 2;
  }

  .react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  .school-suggestion {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .react-autosuggest__suggestion {
    cursor: pointer;
    padding: 10px 20px;
    border-bottom: 1px solid #9c9c9c;

    &:last-child {
      border: none;
    }
  }

  .react-autosuggest__suggestion--highlighted {
    background-color: #ddd;

    &:first-child {
      border-radius: 8px 8px 0 0;
    }

    &:last-child {
      border-radius: 0 0 8px 8px;
    }

    &:first-child:last-child {
      border-radius: 8px;
    }
  }
}
