@import 'styles/colors';

.lite-status {
  background: #259bff !important;
  font-weight: bold;
  color: white !important;
}

.sdp-container {
  position: relative;

  &.profile .alert {
    margin-bottom: 0;
  }

  .sdp-header {
    margin: 0;
    padding: 8px 48px;

    display: flex;
    align-items: center;
    gap: 16px;

    .sdp-avatar {
      padding: 14px;
      background-color: #fff;
      border-radius: 50%;
      border: 2px solid #786d6c;

      img {
        width: 64px;
        height: 64px;
        object-fit: contain;
      }
    }

    .sdp-details {
      padding: 8px 0px;
    }

    .sdp-title {
      display: flex;
      align-items: flex-end;
      gap: 15px;

      h1 {
        margin: 0;
        text-transform: capitalize;
        font-size: 2.3rem;
      }

      .sdp-edit-icon {
        font-size: 1.5rem;
        cursor: pointer;
      }

      .sdp-icons {
        display: flex;
        align-items: center;
        gap: 15px;
        background-color: #0006;
        padding: 8px;
        border-radius: 8px;
      }
    }

    .sdp-header-address {
      font-size: 1.125rem;
      line-height: 1rem;
      margin-top: 0.625rem;
      text-transform: none;
    }
  }

  &-tabs {
    align-items: center;
    box-shadow: 0px 0.0625rem 0px rgba(0, 0, 0, 0.06);
    height: 4.375rem;
    padding-left: 48px;

    .nav-link {
      color: $deep_koamaru;

      &:hover {
        border-color: transparent;
      }
    }

    .nav-link.active {
      color: $amaranth;
      background-color: inherit;
      border-color: transparent;
    }
  }

  .sdp-data-container {
    padding-left: 2% !important;
    padding-right: 2% !important;

    &-row {
      align-items: normal;
    }
  }
}

.links-grid {
  display: flex;
  flex-wrap: wrap;
  padding-top: 20px;

  .link {
    position: relative;
    flex-basis: calc(25% - 10px);
    margin: 5px;
    box-sizing: border-box;

    &::before {
      content: '';
      display: block;
      padding-top: 100%;
    }

    &.disabled {
      a,
      a:hover {
        background: #cccccc;
        color: #ececec;
      }
    }

    .content {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      color: #ffffff;
      text-decoration: none;
      text-align: center;
      padding: 5px 5px 0 5px;
      user-select: none;
      background: #dc3545;
      border-radius: 5px;

      &:hover {
        color: #ffffff;
      }

      svg,
      span {
        display: block;
      }

      svg {
        font-size: 1.6rem;
        margin: 10px 0 5px 0;
        justify-self: center;
      }

      span {
        font-size: 0.8rem;
        line-height: 0.8rem;
        justify-self: flex-end;
      }
    }

    .link-edit {
      display: none;
    }

    &:hover .link-edit {
      display: block;
      justify-self: flex-start;
      color: #ffffff;
      position: absolute;
      top: 0;
      right: 0;
      padding: 3px;
      font-size: 0.8rem;
      z-index: 1000;
      cursor: pointer;
    }
  }
}

.edit-link-popover {
  &.popover {
    overflow-y: visible !important;
    padding: 15px 10px;
    display: flex;

    & .arrow {
      display: block;

      &::before {
        border-top-color: #ffffff;
        bottom: 1px;
      }
    }

    .edit-link-container {
      display: flex;
      width: 100%;

      input {
        flex: 1;
        border: 1px solid $iron;
        border-radius: 4px;
        padding: 5px;
      }

      .btn {
        width: auto;
        margin-left: 4px;
      }
    }
  }
}

.school-basic-info {
  padding-top: 25px;

  table {
    border-radius: 6px;
    table-layout: fixed;
    width: 100%;
  }

  td {
    padding: 0.8em;
    background: #fafafa;
    border: 1px solid #e3e3e3;
    overflow: hidden;
    text-overflow: ellipsis;

    ul {
      padding-left: 1.3rem;
    }
  }

  .field-title {
    font-weight: bold;
  }

  .school-color-preview {
    font-family: monospace;
    font-size: 14px;
  }
}

.mapboxgl-ctrl-logo {
  display: none !important;
}

.school-reports {
  overflow: auto;

  .school-report-header {
    margin-top: 20px;
  }
}
