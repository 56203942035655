@media (max-width: 767px) {
  .month-nav {
    display: none;
  }
}
@media (min-width: 768px) and (max-width: 1189px) {
  .month-nav {
    display: none;
  }
}
@media (min-width: 1190px) {
  .month-nav {
    display: block;
  }
  .saturn-calendar-buttons-box {
    width: 70%;
  }
}
